
.container {
    & * {
        box-sizing: border-box;
    }
    width: 100%;
    height: calc(100vh - 60px);
    .play-box {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        width: 100%;
        height: 100%;
        position: relative;
        .play-video {
            width: calc(30% - 20px);
            overflow: hidden;
            height: 100%;
            border-radius: 10px;
            display: flex;
            align-items: flex-end;
            //transform: translateY(3%);
            video {
                width: calc(100% / 0.3 - 20px);
                transform: translate(-35%);
            }
        }

    }
    .controls-box {
        padding: 0 20px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .play-btns {
            width: 80px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            .play-btn {
                cursor: pointer;
                i {
                    font-size: 25px;
                    color: #409EFF;

                }
                &.pause {
                    i {
                      color: #909399;
                    }
                }
            }
        }
        .play-proccess {
            flex: 1;
        }
    }
}

